// React imports

// Remix imports
import { useMatches } from '@remix-run/react'

// Third party imports

// App imports
import type { GlobalDataAndTrans } from '~/utils/getGlobalDataAndTrans.server'

const useGlobal = (): GlobalDataAndTrans => {
  /**
   * We expect that every leaf-route's loader will return a field `global`.
   */
  const matches = useMatches()
  const routeMatch = matches[matches.length - 1]
  const global = routeMatch?.data?.global

  return global
}

export default useGlobal
